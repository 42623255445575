const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/", "/inscription"],
    exact: true,
    component: "Inscription",
  },
  {
    path: ["/", "/connexion"],
    exact: true,
    component: "Connexion",
  },
  {
    path: ["/", "/comment-ca-marche"],
    exact: true,
    component: "CommentCaMarche",
  },
  {
    path: ["/", "/foire-aux-questions"],
    exact: true,
    component: "Foire",
  },
  {
    path: ["/", "/infos-legales"],
    exact: true,
    component: "Info",
  },
  {
    path: ["/", "/proposer"],
    exact: true,
    component: "ProposerTrajet",
  },
  {
    path: ["/", "/profil"],
    exact: true,
    component: "Profil",
  },
];

export default routes;
